import React from "react";
import {Switch} from "react-router-dom";
import { useScrollToTopHandler } from "./hooks/useScrollToTopHandler";

//Route Types
import ContextAwareRoute from "./Routes/ContextAwareRoute";

//Layouts
import StaticPageLayout from "../Layouts/StaticPageLayout";
import {getComputedAvailableRoutes} from "./RoutesMapping";

const ApplicationRouter = () => {
	useScrollToTopHandler();

	return (
		<Switch>
			{getComputedAvailableRoutes().map((route, index) => (
				<ContextAwareRoute key={index} exact layout={StaticPageLayout} {...route} />
			))}
		</Switch>
	);
};

export default ApplicationRouter;
