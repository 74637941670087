import styled from "styled-components";
import {Link} from "react-router-dom";
import {Close} from "@material-ui/icons";

export const HeaderContainer = styled.div`
	background-color: #fff;
	height: 90px;
	display: grid;
	align-items: center;
	border: 1px solid #f4f4f4;

	${props => props.isScrolled === true && `
		background-color: white;
		box-shadow: 0 5px 10px 0 rgba(140, 140, 140, 0.12);
		border: none;
	`}
`;

export const HeaderBarComponentContainer = styled.div`
	display: grid;
	position: relative;
	justify-content: space-between;
	align-items: center;
	grid-template-columns: 130px 1fr;
	grid-column-gap: 20px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-template-columns: 130px auto;
	}
`;

export const LogoContainer = styled(Link)`
	position: relative;
`;

export const HeaderMenuWrapper = styled.div`
	position: relative;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 20px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.lg)} {
		grid-column-gap: 0;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		width: 60vw;
		height: auto;
		padding: 40px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: max-content auto;
		justify-content: space-between;
		grid-row-gap: 40px;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		width: 100vw;
	}
`;

export const MenuLinksWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, auto);
	justify-content: space-between;
	align-items: center;
	
	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.lg)} {
		grid-column-gap: 30px;
	}

	@media all and ${({theme: {screen: {up, breakpoints}}}) => up(breakpoints.md)} {
		border-right: 1px solid #E5E5E5;
		padding: 0 30px;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
	}
`;

export const EntityTypeMenuButtonsWrapper = styled.div`
  display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 50px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-template-columns: 1fr;
		grid-row-gap: 10px;
	}
`;

export const MenuItem = styled(Link)`
	text-decoration: none;
	color: #000;

	font-size: 14px;
	font-weight: 400;
	text-align: center;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		padding: 10px 0;

		&:hover {
			background-color: #f9f9f9;
		}
	}
`;

export const AuthButtonsWrapper = styled.div`
  display: grid;
	grid-template-columns: repeat(2, max-content);
	grid-column-gap: 10px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.lg)} {
		grid-column-gap: 0;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
	}
`;

export const HeaderButton = styled(Link)`
	padding: 10px 30px;
	text-decoration: none;

	font-size: 14px;
	font-weight: 400;
	text-align: center;

	color: #000;
	border-radius: 37px;
	
	&:hover {
		border-color: black;
	}
`;

export const LoginButton = styled(HeaderButton)``;
export const RegisterButton = styled(HeaderButton)`
	display: grid;
	grid-template-columns: 1fr max-content;
	grid-column-gap: 10px;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #E5E5E5;
	
	& > img {
		height: 15px;
		padding: 0;
		margin: 0;
	}
`;

export const DrawerCloseButton = styled(Close)`
	position: fixed;
  color: #363636;
	font-size: 45px;
	
	top: 15px;
	right: 15px;
	cursor: pointer;
	z-index: 100;
`;
