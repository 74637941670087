import {
	ABOUT_US_ROUTE,
	AML_POLICY_ROUTE,
	BUSINESS_BILLING_ROUTE,
	BUSINESS_CARD_ROUTE,
	BUSINESS_SEND_MONEY_ROUTE,
	BUSINESS_PAYMENT_ROUTE,
	CONTACT_US_ROUTE,
	GDPR_POLICY_ROUTE,
	KYC_POLICY_ROUTE,
	PERSONAL_EXTENSION_ROUTE,
	PERSONAL_HOMEPAGE_ROUTE,
	TERMS_AND_CONDITIONS,
} from "../../../../../Navigation/NavigationConstants";
import getTextWithAppNameReplaced from "../../../../../Helpers/getTextWithAppNameReplaced";

export default function useFooterLinks(){
	return [
		{title: 'Business', links: [
			{title: 'Card', to: BUSINESS_CARD_ROUTE},
			{title: 'Payment', to: BUSINESS_PAYMENT_ROUTE},
			{title: getTextWithAppNameReplaced('{appName} Send'), to: BUSINESS_SEND_MONEY_ROUTE},
			{title: 'Billing', to: BUSINESS_BILLING_ROUTE},
		]},
		{title: 'Personal', links: [
			{title: 'Homepage', to: PERSONAL_HOMEPAGE_ROUTE},
			{title: 'Extension', to: PERSONAL_EXTENSION_ROUTE},
		]},
		{title: 'Company', links: [
			{title: 'About', to: ABOUT_US_ROUTE},
			{title: 'Contact', to: CONTACT_US_ROUTE},
		]},
		{title: 'Legal', links: [
			{title: 'Terms and Conditions', to: TERMS_AND_CONDITIONS},
			{title: 'GDPR Policy', to: GDPR_POLICY_ROUTE},
			{title: 'AML Policy', to: AML_POLICY_ROUTE},
			{title: 'KYC Policy', to: KYC_POLICY_ROUTE},
		]}
	];
}
