import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import {Helmet} from "react-helmet";

import './library/Theme/DefaultTheme/fonts/style.css';
import './tailwind.css';

import ApplicationRouter from "./library/Navigation/ApplicationRouter";
import GlobalProviders from "./library/Providers/SharedProviders/GlobalProviders";
import Environment from "./library/Environment";

const App = () => {
	return(
		<React.Fragment>
			<Helmet>
				<title>{Environment.APP_NAME}</title>
			</Helmet>
			<GlobalProviders>
				<BrowserRouter>
					<ApplicationRouter />
				</BrowserRouter>
			</GlobalProviders>
		</React.Fragment>
	);
};

ReactDOM.render(
	<App />,
	document.getElementById('root')
);
