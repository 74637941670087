import styled from "styled-components";
import {Link} from "react-router-dom";

export const ComponentContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, max-content);
	grid-column-gap: 40px;
	
	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-template-columns: 1fr;
	}
`;

export const MenuItemContainer = styled.div`
	position: relative;
`;

export const DropdownMenuItemTitle = styled.div`
	display: grid;
	grid-template-columns: max-content ${25/2}px;
	grid-column-gap: 10px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 14px;
	color: black;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		display: none;
	}
`;

export const MenuItemContentBox = styled.div`
	padding: 15px 0;

	@media all and ${({theme: {screen: {up, breakpoints}}}) => up(breakpoints.md)} {
		display: ${props => props.isVisible === true ? 'block' : 'none'};
		position: absolute;
		top: calc(100%);
		left: -10px;
		min-width: 100%;
	}
`;

export const MenuItemContentContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
	
	background-color: #fff;
	box-shadow: 0 0 15px 4px rgba(0,0,0,0.08);
	padding: 20px;
	border-radius: 10px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-template-columns: 1fr;
		grid-row-gap: 40px;
		box-shadow: 0 0 15px 3px rgba(0,0,0,0.04);
		padding: 30px;
	}
`;

export const MenuItemContentSection = styled.div`
	min-width: 190px;
	display: grid;
	grid-row-gap: 25px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-row-gap: 25px;
	}
`;

export const MenuItemContentSectionTitle = styled.div`
  display: grid;
	grid-template-columns: 30px 1fr;
	grid-column-gap: 10px;
	align-items: center;
`;

export const MenuItemContentSectionTitleIcon = styled.div`
  background-image: url(${props => props.icon});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
	height: 30px;
`;

export const MenuItemContentSectionTitleText = styled.div`
	font-size: 15px;
	font-weight: 500;
  color: #CED5DB
`;

export const MenuItemContentSectionItemsWrapper = styled.div`
	display: grid;
	grid-row-gap: 10px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-row-gap: 20px;
	}
`;

export const MenuItemContentSectionItemContainer = styled(Link)`
  font-size: 15px;
	font-weight: 400;
`;
