import React, {createContext, useEffect, useMemo, useState} from "react";
import intl from "react-intl-universal";
import '../../Translate/helpers/localization-polyfill';

import { determineCurrentLocale } from "../../Translate/helpers";

import {
	DEFAULT_LANGUAGE,
	APP_LANGUAGE_STORAGE_KEY,
	LOCALES,
} from "../../Translate/LocalizationConstants";
import translationProxy from "../../Translate/helpers/translation-proxy";

const initialState = {
	language: DEFAULT_LANGUAGE,
	translations: LOCALES[DEFAULT_LANGUAGE],
}

export const LocalisationContext = createContext(initialState);
export const LocalisationProvider = ({children}) => {
	const [language, setLanguage] = useState(initialState.language);
	const [translations, setTranslations] = useState(initialState.translations);

	useEffect(() => {
		let currentLocale = determineCurrentLocale();

		intl.init({
			currentLocale: currentLocale,
			locales: LOCALES
		});

		setLanguage(currentLocale);
	}, []);

	const changeLanguage = (language) => {
		setLanguage(language);
		localStorage.setItem(APP_LANGUAGE_STORAGE_KEY, language);
	}

	useEffect(() => {
		setTranslations(LOCALES[language]);
	}, [language]);

	const translationsComputed = useMemo(
		() => translationProxy(translations),
		[translations]
	)

	return (
		<LocalisationContext.Provider
			value={{
				translations: translationsComputed,
				language,
				setLanguage: changeLanguage,
			}}>
			{children}
		</LocalisationContext.Provider>
	);
}
