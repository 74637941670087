import React, {useContext} from 'react';
import {Facebook, Instagram, LinkedIn, Twitter, YouTube} from "@material-ui/icons";
import {LocalisationContext} from "../../../../../../Providers/SharedProviders/LocalisationProvider";

import {
	CopyrightText,
	FooterCopyrightBarContainer,
	SocialIconItem,
	SocialIconsContainer
} from "./components/Styled";

export default function FooterCopyrightBar(){
	const {translations} = useContext(LocalisationContext);

	const socialItems = [
		{icon: Facebook, link: '#'},
		{icon: Twitter, link: '#'},
		{icon: Instagram, link: '#'},
		{icon: LinkedIn, link: '#'},
		{icon: YouTube, link: '#'},
	]
  return (
    <FooterCopyrightBarContainer>
	    <CopyrightText>{translations.sections.footer.copyrightText}</CopyrightText>
      <SocialIconsContainer>
	      {socialItems.map(({icon: IconComponent, link}, index) =>
		      <SocialIconItem key={index} to={link}>
			      <IconComponent />
		      </SocialIconItem>
	      )}
      </SocialIconsContainer>
    </FooterCopyrightBarContainer>
  );
};
