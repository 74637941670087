const gray = {
	50: "#f3f4f6",
	100: "#CCD0D3",
	200: "#8F9BB3",
	300: "#A7A7A7",
	400: "#929292",
	500: "#878787",
	600: "#474747",
	700: "#2a2a2a",
};

 const blue= {
	50: "#e6effe",
	100: "#159AD5",
	200: "#3c82ff",
	300: "#0570F0",
	400: "#0469d4",
	500: "#4169E9",
	600: "#365ACE",
	700: "#243B7F",
	800: "#213676",
  primary: '#0069F0',
};

const yellow= {
	500: "#FCBD01",
};

const COLORS = {gray, blue, yellow};
module.exports = COLORS;
