import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import { SwipeableDrawer, useMediaQuery, useTheme } from "@material-ui/core";
import { Menu } from "@material-ui/icons";

import {
	BUSINESS_HOMEPAGE_ROUTE, GET_STARTED_ROUTE, LOGIN_ROUTE, PERSONAL_HOMEPAGE_ROUTE,
} from "../../../../Navigation/NavigationConstants";

import {LocalisationContext} from "../../../../Providers/SharedProviders/LocalisationProvider";
import WidthLimitContainer from "../../../../../components/Layout/WidthLimitContainer";
import CompanyLogo from "../../../../../components/Atomic/Branding/CompanyLogo";

import {
	HeaderContainer,
	HeaderBarComponentContainer,
	LogoContainer,
	HeaderMenuWrapper,
	MenuLinksWrapper,
	MenuItem,
	AuthButtonsWrapper,
	LoginButton,
	RegisterButton,
	DrawerCloseButton,
	EntityTypeMenuButtonsWrapper,
} from "./styles";

import DropdownMenuItems from "./components/DropdownMenuItems";
import signInIcon from './assets/sign-in-icon@2x.png';

const StaticPageLayoutHeader = () => {
	const {translations} = useContext(LocalisationContext);

	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
	const isExtraSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [pageIsScrolled, setPageIsScrolled] = useState(false);

	const UsedHeaderMenuContainer = isSmallDevice ? SwipeableDrawer : React.Fragment;
	const toggleDrawer = useCallback(
		() => setIsDrawerOpen(!isDrawerOpen),
		[setIsDrawerOpen, isDrawerOpen]
	);

	const drawerComponentProps = useMemo(() => {
		if(isSmallDevice === false) {
			return {};
		}

		return {
			onClose: toggleDrawer,
			onOpen: toggleDrawer,
			open: isDrawerOpen,
		}
	}, [isSmallDevice, toggleDrawer, isDrawerOpen])

	useEffect(() => {
		isSmallDevice === false && setIsDrawerOpen(false);
	}, [isSmallDevice]);

	function scrollHandler(){
		setPageIsScrolled(window.scrollY > 10);
	}

	useEffect(() => {
		scrollHandler();
		window.addEventListener('scroll', scrollHandler);
		return () => {
			window.removeEventListener("scroll", scrollHandler)
		}
	}, []);

	return (
		<HeaderContainer isScrolled={pageIsScrolled}>
			<WidthLimitContainer>
				<HeaderBarComponentContainer>
					<LogoContainer to={'/'}>
						<CompanyLogo />
					</LogoContainer>
					{isSmallDevice === true && (
						<Menu onClick={toggleDrawer} />
					)}
					<UsedHeaderMenuContainer {...drawerComponentProps}>
						<HeaderMenuWrapper>
							{isExtraSmallDevice === true && (
								<DrawerCloseButton color={'white'} onClick={toggleDrawer} />
							)}

							<MenuLinksWrapper>
								<EntityTypeMenuButtonsWrapper>
									<MenuItem to={PERSONAL_HOMEPAGE_ROUTE}>Personal</MenuItem>
									<MenuItem to={BUSINESS_HOMEPAGE_ROUTE}>Business</MenuItem>
								</EntityTypeMenuButtonsWrapper>

								<DropdownMenuItems />
							</MenuLinksWrapper>

							<AuthButtonsWrapper>
								<LoginButton
									to={{pathname: LOGIN_ROUTE}}
									target={'_parent'}>{translations.sections.header.loginButton}
								</LoginButton>
								<RegisterButton to={GET_STARTED_ROUTE}>
									{translations.sections.header.registerButton}
									<img alt={''} src={signInIcon} />
								</RegisterButton>
							</AuthButtonsWrapper>
						</HeaderMenuWrapper>
					</UsedHeaderMenuContainer>
				</HeaderBarComponentContainer>
			</WidthLimitContainer>
		</HeaderContainer>
	);
};

export default StaticPageLayoutHeader;
