import styled from "styled-components";
import {Link} from "react-router-dom";

export const FooterCopyrightBarContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		flex-direction: column-reverse;
	}
`;

export const CopyrightText = styled.div`
	display: flex;
	font-size: 15px;
	font-weight: 400;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		margin-top: 20px;
	}
`;

export const SocialIconsContainer = styled.div`
  display: flex;
`;

export const SocialIconItem = styled(Link)`
  display: flex;
	margin-left: 10px;
	color: inherit;
`;
