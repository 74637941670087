import {lazy} from "react";
import Environment from "../Environment";
import ComingSoonScreen from "../../screens/ComingSoonScreen";

import {
	ABOUT_US_ROUTE, AML_POLICY_ROUTE, BUSINESS_BILLING_ROUTE,
	BUSINESS_CARD_ROUTE,
	BUSINESS_HOMEPAGE_ROUTE,
	BUSINESS_PAYMENT_ROUTE,
	BUSINESS_SEND_MONEY_ROUTE,
	CONTACT_US_ROUTE, GDPR_POLICY_ROUTE,
	GET_STARTED_ROUTE, KYC_POLICY_ROUTE,
	PERSONAL_EXTENSION_ROUTE,
	PERSONAL_HOMEPAGE_ROUTE, TERMS_OF_SERVICE_ROUTE,
	TERMS_AND_CONDITIONS,
	WALLET_ROUTE
} from "./NavigationConstants";

import {
	CRIEX_APPLICATION_TYPE,
	DEVELOPMENT_ENVIRONMENT,
	PRODUCTION_ENVIRONMENT,
	STAGING_ENVIRONMENT
} from "../Environment/EnvironmentConstants";

const ContactUsScreen = lazy(() => import("../../screens/ContactUsScreen"));
const AboutUsScreen = lazy(() => import("../../screens/AboutUsScreen"));
const WalletScreen = lazy(() => import("../../screens/WalletScreen"));

const KycPolicyScreen = lazy(() => import("../../screens/PolicyScreens/KycPolicyScreen"));
const AmlPolicyScreen = lazy(() => import("../../screens/PolicyScreens/AmlPolicyScreen"));
const GdprPolicyScreen = lazy(() => import("../../screens/PolicyScreens/GdprPolicyScreen"));
const TermsOfServiceScreen = lazy(() => import("../../screens/PolicyScreens/TermsOfServiceScreen"));
const TermsAndConditionsScreen = lazy(() => import("../../screens/PolicyScreens/TermsAndConditionsScreen"));

//Personal Screens
const PersonalHomepageScreen = lazy(() => import("../../screens/Personal/PersonalHomepageScreen"));
const PersonalExtensionScreen = lazy(() => import("../../screens/Personal/PersonalExtensionScreen"));

//Business Screens
const BusinessCardScreen = lazy(() => import("../../screens/Business/BusinessCardScreen"));
const BusinessBillingScreen = lazy(() => import("../../screens/Business/BusinessBillingScreen"));
const BusinessOnlinePaymentsScreen = lazy(() => import("../../screens/Business/BusinessOnlinePaymentsScreen"));
const BusinessSendMoneyScreen = lazy(() => import("../../screens/Business/BusinessSendMoneyScreen"));
const BusinessHomepageScreen = lazy(() => import("../../screens/Business/BusinessHomepageScreen"));
const GetStartedScreen = lazy(() => import ("../../screens/GetStartedScreen"));

const DEFAULT_MAPPING = {
	[PERSONAL_HOMEPAGE_ROUTE]: PersonalHomepageScreen,
	[GET_STARTED_ROUTE]: GetStartedScreen,
	[PERSONAL_EXTENSION_ROUTE]: PersonalExtensionScreen,
	[CONTACT_US_ROUTE]: ContactUsScreen,
	[ABOUT_US_ROUTE]: AboutUsScreen,
	[WALLET_ROUTE]: WalletScreen,
	[BUSINESS_CARD_ROUTE]: BusinessCardScreen,
	[BUSINESS_PAYMENT_ROUTE]: BusinessOnlinePaymentsScreen,
	[BUSINESS_SEND_MONEY_ROUTE]: BusinessSendMoneyScreen,
	[BUSINESS_HOMEPAGE_ROUTE]: BusinessHomepageScreen,
	[BUSINESS_BILLING_ROUTE]: BusinessBillingScreen,
	[KYC_POLICY_ROUTE]: KycPolicyScreen,
	[AML_POLICY_ROUTE]: AmlPolicyScreen,
	[GDPR_POLICY_ROUTE]: GdprPolicyScreen,
	[TERMS_OF_SERVICE_ROUTE]: TermsOfServiceScreen,
	[TERMS_AND_CONDITIONS]: TermsAndConditionsScreen,
}

const CRIEX_CUSTOM_MAPPINGS = {
	default: {},
	[DEVELOPMENT_ENVIRONMENT]: {},
	[STAGING_ENVIRONMENT]: {},
	[PRODUCTION_ENVIRONMENT]: {},
}

const PAPELX_CUSTOM_MAPPINGS = {
	default: {},
	[DEVELOPMENT_ENVIRONMENT]: {},
	[STAGING_ENVIRONMENT]: {},
	[PRODUCTION_ENVIRONMENT]: {},
}

const getCustomScreenForRoute = (route) => {
	const applicationType = Environment.APPLICATION_TYPE;
	const applicationEnvironment = Environment.environmentType;

	const customMappingsObject = applicationType === CRIEX_APPLICATION_TYPE ? CRIEX_CUSTOM_MAPPINGS : PAPELX_CUSTOM_MAPPINGS;

	if(customMappingsObject[applicationEnvironment].hasOwnProperty(route)){
		return customMappingsObject[applicationEnvironment][route];
	}

	if(customMappingsObject.default.hasOwnProperty(route)){
		return customMappingsObject.default[route];
	}

	return null;
}

const getComputedRoutesToScreen = () => {
	let computedMappings = {...DEFAULT_MAPPING}
	Object.keys(DEFAULT_MAPPING).map(routeName => {
		const customScreen = getCustomScreenForRoute(routeName);
		if(customScreen) {
			computedMappings[routeName] = customScreen;
		}
	})
	return {
		...computedMappings,
	}
}

const DEFAULT_AVAILABLE_ROUTES = [
	PERSONAL_HOMEPAGE_ROUTE,
	GET_STARTED_ROUTE,
	PERSONAL_EXTENSION_ROUTE,
	CONTACT_US_ROUTE,
	ABOUT_US_ROUTE,
	WALLET_ROUTE,
	BUSINESS_CARD_ROUTE,
	BUSINESS_PAYMENT_ROUTE,
	BUSINESS_SEND_MONEY_ROUTE,
	BUSINESS_HOMEPAGE_ROUTE,
	BUSINESS_BILLING_ROUTE,
	KYC_POLICY_ROUTE,
	AML_POLICY_ROUTE,
	GDPR_POLICY_ROUTE,
	TERMS_OF_SERVICE_ROUTE,
	TERMS_AND_CONDITIONS
];

const CRIEX_AVAILABLE_ROUTES = {
	[DEVELOPMENT_ENVIRONMENT]: DEFAULT_AVAILABLE_ROUTES,
	[STAGING_ENVIRONMENT]: DEFAULT_AVAILABLE_ROUTES,
	[PRODUCTION_ENVIRONMENT]: DEFAULT_AVAILABLE_ROUTES,
};
const PAPELX_AVAILABLE_ROUTES = {
	[DEVELOPMENT_ENVIRONMENT]: DEFAULT_AVAILABLE_ROUTES,
	[STAGING_ENVIRONMENT]: DEFAULT_AVAILABLE_ROUTES,
	[PRODUCTION_ENVIRONMENT]: DEFAULT_AVAILABLE_ROUTES,
};

const CRIEX_ROUTE_OPTIONS = {
	[DEVELOPMENT_ENVIRONMENT]: {},
	[STAGING_ENVIRONMENT]: {},
	[PRODUCTION_ENVIRONMENT]: {},
}

const PAPELX_ROUTE_OPTIONS = {
	[DEVELOPMENT_ENVIRONMENT]: {},
	[STAGING_ENVIRONMENT]: {},
	[PRODUCTION_ENVIRONMENT]: {},
}

const getRouteOptions = (route) => {
	const applicationType = Environment.APPLICATION_TYPE;
	const applicationEnvironment = Environment.environmentType;
	const routeOptionObject = applicationType === CRIEX_APPLICATION_TYPE ? CRIEX_ROUTE_OPTIONS : PAPELX_ROUTE_OPTIONS;

	if(routeOptionObject[applicationEnvironment].hasOwnProperty(route)){
		return routeOptionObject[applicationEnvironment][route];
	}

	return {};
};

const getComputedRoutes = () => {
	const applicationType = Environment.APPLICATION_TYPE;
	const applicationEnvironment = Environment.environmentType;
	const routesArray = applicationType === CRIEX_APPLICATION_TYPE ? CRIEX_AVAILABLE_ROUTES : PAPELX_AVAILABLE_ROUTES;

	return routesArray[applicationEnvironment];
}

export const getComputedAvailableRoutes = () => {
	const routeMappingToScreen = getComputedRoutesToScreen();
	const availableRoutes = getComputedRoutes();
	return availableRoutes.map(routeName => {
		return {
			path: routeName,
			component: routeMappingToScreen[routeName],
			...getRouteOptions(routeName),
		}
	})
}
