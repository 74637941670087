import React, {useState} from 'react';

import {
	ABOUT_US_ROUTE,
	BUSINESS_BILLING_ROUTE,
	BUSINESS_CARD_ROUTE,
	BUSINESS_SEND_MONEY_ROUTE,
	BUSINESS_PAYMENT_ROUTE,
	CONTACT_US_ROUTE,
	PERSONAL_EXTENSION_ROUTE,
	WALLET_ROUTE
} from "../../../../../../Navigation/NavigationConstants";

import {
	ComponentContainer,
	MenuItemContainer,

	MenuItemContentBox,
	DropdownMenuItemTitle,

	MenuItemContentContainer,
	MenuItemContentSection,
	MenuItemContentSectionTitle,
	MenuItemContentSectionItemContainer,
	MenuItemContentSectionItemsWrapper,
	MenuItemContentSectionTitleText, MenuItemContentSectionTitleIcon
} from "./styles";

import menuCompanyIcon from './assets/menu-company-icon@2x.png';
import menuPersonalIcon from './assets/menu-personal-icon.svg';
import arrowIcon from './assets/dropdown-menu-arrow@2x.png';
import getTextWithAppNameReplaced from "../../../../../../Helpers/getTextWithAppNameReplaced";

const DropdownMenuItems = () => {
	const PRODUCTS_MENU_ID = 1;
	const COMPANY_MENU_ID = 2;

	const [menuIdVisible, setMenuIdVisible] = useState(null);
	const changeMenuId = (menuIdDisplayed) => () => setMenuIdVisible(menuIdDisplayed)

  return (
	  <ComponentContainer>
		  <MenuItemContainer
			  onMouseEnter={changeMenuId(PRODUCTS_MENU_ID)}
			  onMouseLeave={changeMenuId(null)}
		  >
			  <DropdownMenuItemTitle>
				  Products
				  <img alt={''} src={arrowIcon} />
			  </DropdownMenuItemTitle>
			  <MenuItemContentBox isVisible={menuIdVisible === PRODUCTS_MENU_ID}>
					<MenuItemContentContainer>
						<MenuItemContentSection>
							<MenuItemContentSectionTitle>
								<MenuItemContentSectionTitleIcon icon={menuPersonalIcon} />
								<MenuItemContentSectionTitleText>FOR INDIVIDUALS</MenuItemContentSectionTitleText>
							</MenuItemContentSectionTitle>
							<MenuItemContentSectionItemsWrapper>
								<MenuItemContentSectionItemContainer to={BUSINESS_CARD_ROUTE}>Card</MenuItemContentSectionItemContainer>
								<MenuItemContentSectionItemContainer to={WALLET_ROUTE}>Wallet</MenuItemContentSectionItemContainer>
								<MenuItemContentSectionItemContainer to={PERSONAL_EXTENSION_ROUTE}>Extension</MenuItemContentSectionItemContainer>
							</MenuItemContentSectionItemsWrapper>
						</MenuItemContentSection>
						<MenuItemContentSection>
							<MenuItemContentSectionTitle>
								<MenuItemContentSectionTitleIcon icon={menuCompanyIcon} />
								<MenuItemContentSectionTitleText>FOR BUSINESS</MenuItemContentSectionTitleText>
							</MenuItemContentSectionTitle>
							<MenuItemContentSectionItemsWrapper>
								<MenuItemContentSectionItemContainer to={BUSINESS_PAYMENT_ROUTE}>Online Payments</MenuItemContentSectionItemContainer>
								<MenuItemContentSectionItemContainer to={BUSINESS_BILLING_ROUTE}>Billing</MenuItemContentSectionItemContainer>
								<MenuItemContentSectionItemContainer to={BUSINESS_SEND_MONEY_ROUTE}>
									{getTextWithAppNameReplaced('{appName} Send')}
								</MenuItemContentSectionItemContainer>
							</MenuItemContentSectionItemsWrapper>
						</MenuItemContentSection>
					</MenuItemContentContainer>
			  </MenuItemContentBox>
		  </MenuItemContainer>
		  <MenuItemContainer
			  onMouseEnter={changeMenuId(COMPANY_MENU_ID)}
			  onMouseLeave={changeMenuId(null)}
		  >
			  <DropdownMenuItemTitle>
				  Company
				  <img alt={''} src={arrowIcon} />
			  </DropdownMenuItemTitle>
			  <MenuItemContentBox isVisible={menuIdVisible === COMPANY_MENU_ID}>
				  <MenuItemContentContainer>
					  <MenuItemContentSection>
							<MenuItemContentSectionTitle>
								<MenuItemContentSectionTitleIcon icon={menuCompanyIcon} />
								<MenuItemContentSectionTitleText>COMPANY</MenuItemContentSectionTitleText>
							</MenuItemContentSectionTitle>
						  <MenuItemContentSectionItemsWrapper>
							  <MenuItemContentSectionItemContainer to={ABOUT_US_ROUTE}>About Us</MenuItemContentSectionItemContainer>
							  <MenuItemContentSectionItemContainer to={CONTACT_US_ROUTE}>Contact</MenuItemContentSectionItemContainer>
						  </MenuItemContentSectionItemsWrapper>
					  </MenuItemContentSection>
				  </MenuItemContentContainer>
			  </MenuItemContentBox>
		  </MenuItemContainer>
	  </ComponentContainer>
  );
};

export default DropdownMenuItems;
