import React from 'react';

import {
	CompanyInformationSectionContainer,
	StyledCompanyLogo
} from "./components/Styled";

const FooterCompanyInformationSection = () => {
  return (
    <CompanyInformationSectionContainer>
	    <StyledCompanyLogo colorVariant={'light'} />
    </CompanyInformationSectionContainer>
  );
};

export default FooterCompanyInformationSection;
