import styled from 'styled-components'

export const StaticPageLayoutWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	height: 100vh;
`;

export const HeaderWrapper = styled.div`
	box-sizing: border-box;
	align-items: center;
	
	color: #748292;
	width: 100%;
	z-index: 100;

`;
