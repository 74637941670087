import React from 'react';

import {ThemeProvider} from '../../Theme/ThemeProvider';
import {LocalisationProvider} from "./LocalisationProvider";

const GlobalProviders = ({children}) => {
	return (
		<ThemeProvider>
			<LocalisationProvider>
				{children}
			</LocalisationProvider>
		</ThemeProvider>
	);
};

export default GlobalProviders;
