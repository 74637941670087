import styled from "styled-components";
import {Link} from "react-router-dom";

export const FooterLinksSectionContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, auto);
	grid-column-gap: 50px;
	justify-items: end;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.lg)} {
		justify-items: initial;
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 50px;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		grid-template-columns: repeat(1, 1fr);
	}
`;

export const FooterLinksSectionItem = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: max-content;
	grid-row-gap: 35px;
`;

export const FooterLinksSectionTitle = styled.div`
	font-size: 25px;
	line-height: 26px;
	font-weight: 500;
	color: white;
`;

export const FooterLinksSectionItemsLinksContainer = styled.div`
	cursor: pointer;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: max-content;
	grid-row-gap: 15px;
`;

export const FooterLinksSectionItemsLinkText = styled(Link)`
	display: flex;
	color: inherit;
	text-decoration: none;
	font-size: 17px;
	line-height: 25px;
	font-weight: 300;
	
	&:hover {
		text-decoration: underline;
	}
`;
