import {CRIEX_APPLICATION_TYPE, PAPELX_APPLICATION_TYPE} from "../Environment/EnvironmentConstants";
import Environment from "../Environment";

const LOGIN_ROUTE_MAP = {
	[CRIEX_APPLICATION_TYPE]: 'https://my.criex.io/session/login',
	[PAPELX_APPLICATION_TYPE]: '#'
};
export const LOGIN_ROUTE = LOGIN_ROUTE_MAP[Environment.APPLICATION_TYPE];

const GET_STARTED_ROUTE_MAP = {
	[CRIEX_APPLICATION_TYPE]: '/get-started',
	[PAPELX_APPLICATION_TYPE]: '#'
};
export const GET_STARTED_ROUTE = GET_STARTED_ROUTE_MAP[Environment.APPLICATION_TYPE];

export const CONTACT_US_ROUTE = '/contact-us';
export const ABOUT_US_ROUTE = '/about';
export const WALLET_ROUTE = '/wallet';
export const REGISTER_ROUTE = '/session/register';

export const PERSONAL_HOMEPAGE_ROUTE = '/';
export const PERSONAL_EXTENSION_ROUTE = '/extension';

export const BUSINESS_HOMEPAGE_ROUTE = '/business';
export const BUSINESS_CARD_ROUTE = '/business/card';
export const BUSINESS_PAYMENT_ROUTE = '/business/online-payments';
export const BUSINESS_BILLING_ROUTE = '/business/billing';

const BUSINESS_SEND_MONEY_ROUTE_MAP = {
	[CRIEX_APPLICATION_TYPE]: '/business/criex-send',
	[PAPELX_APPLICATION_TYPE]: '/business/papelx-send'
};
export const BUSINESS_SEND_MONEY_ROUTE = BUSINESS_SEND_MONEY_ROUTE_MAP[Environment.APPLICATION_TYPE];


export const KYC_POLICY_ROUTE = '/kyc-policy';
export const AML_POLICY_ROUTE = '/aml-policy';
export const GDPR_POLICY_ROUTE = '/gdpr-policy';
export const TERMS_OF_SERVICE_ROUTE = '/terms-of-service';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
