import styled from 'styled-components';

export const skewSizeInPixels = 130;

export const StaticPageLayoutFooterContainer = styled.div`
	background-color: #081125;
	color: #748292;
	clip-path: polygon(0% ${skewSizeInPixels}px, 100% 0%, 100% 100%, 0% 100%);
`;

export const FooterContentContainer = styled.div`
	padding: 140px 0;
	padding-top: calc(${skewSizeInPixels}px + 60px);
	display: grid;
	grid-column-gap: 40px;
	grid-template-columns: 1fr 2fr;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.lg)} {
		grid-template-columns: 2fr 3fr;
	}
	
	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-template-columns: 1fr;
		grid-row-gap: 100px;
	}
	
	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		grid-template-columns: 1fr;
		grid-row-gap: 50px;
		padding: 100px 0;
		padding-top: calc(${skewSizeInPixels}px + 60px);
	}
`;

export const FooterCompanyInformationSectionWrapper = styled.div``;
export const FooterLinksSectionWrapper = styled.div``;

export const StaticPageLayoutFooterCopyrightBarWrapper = styled.div`
	background-color: #22314E;
	color: #566D9A;
	padding: 20px;
`;
