import React from 'react';

import FooterCompanyInformationSection from "./components/FooterCompanyInformationSection";
import FooterLinksSection from "./components/FooterLinksSection";
import FooterCopyrightBar from "./components/FooterCopyrightBar";

import useFooterLinks from "./hooks/useFooterLinks";
import useCompanyInfo from "./hooks/useCompanyInfo";
import useSocialLinks from "./hooks/useSocialLinks";

import {
	FooterCompanyInformationSectionWrapper,
	FooterContentContainer,
	FooterLinksSectionWrapper,
	StaticPageLayoutFooterContainer,
	StaticPageLayoutFooterCopyrightBarWrapper
} from "./components/Styled";
import WidthLimitContainer from "../../../../../components/Layout/WidthLimitContainer";

const StaticPageLayoutFooter = () => {
	const footerLinksSections = useFooterLinks();
	const companyInfo = useCompanyInfo();
	const socialLinks = useSocialLinks();

  return (
	  <StaticPageLayoutFooterContainer>
		  <WidthLimitContainer>
				  <FooterContentContainer>
					  <FooterCompanyInformationSectionWrapper>
						  <FooterCompanyInformationSection {...companyInfo} />
					  </FooterCompanyInformationSectionWrapper>
					  <FooterLinksSectionWrapper>
						  <FooterLinksSection linkSections={footerLinksSections} />
					  </FooterLinksSectionWrapper>
				  </FooterContentContainer>
		  </WidthLimitContainer>

		  <StaticPageLayoutFooterCopyrightBarWrapper>
			  <WidthLimitContainer>
			    <FooterCopyrightBar {...socialLinks} />
			  </WidthLimitContainer>
		  </StaticPageLayoutFooterCopyrightBarWrapper>
	  </StaticPageLayoutFooterContainer>
  );
};

export default StaticPageLayoutFooter;
