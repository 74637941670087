/* eslint-disable */
String.prototype.replaceParameters =
	String.prototype.replaceParameters ||
	function () {
		'use strict';
		let str = this.toString();
		if (arguments.length) {
			const typeOfArguments = typeof arguments[0];
			let key;
			const args =
				typeOfArguments === 'string' || typeOfArguments === 'number'
					? Array.prototype.slice.call(arguments)
					: arguments[0];

			for (key in args) {
				str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
			}
		}

		return str;
	};
