import getTextWithAppNameReplaced from "../../Helpers/getTextWithAppNameReplaced";

export default function translationProxy(proxiedObject) {
	return new Proxy(
		proxiedObject,
		{
			get: function(obj, name) {
				if(typeof obj[name] === 'string'){
					return getTextWithAppNameReplaced(obj[name])
				}

				if(proxiedObject.hasOwnProperty('length')){
					return obj[name];
				}

				return translationProxy(obj[name]);
			},
		}
	);
}
