import {
	CRIEX_APPLICATION_TYPE,
	PAPELX_APPLICATION_TYPE,
	DEFAULT_APPLICATION_TYPE,
	DEVELOPMENT_ENVIRONMENT,
	STAGING_ENVIRONMENT,
	PRODUCTION_ENVIRONMENT
} from "./EnvironmentConstants";

export function getApplicationConfig(){
	const criexCommonEnvironment = require('./envs/CRIEX/common.json');
	const papelxCommonEnvironment = require('./envs/PAPELX/common.json');

	const applicationEnvironmentMap = {
		[CRIEX_APPLICATION_TYPE]: criexCommonEnvironment,
		[PAPELX_APPLICATION_TYPE]: papelxCommonEnvironment,
	}

	const applicationEnvironmentType = process.env.REACT_APP_APPLICATION_TYPE || DEFAULT_APPLICATION_TYPE;
	return Object.assign(
		{APPLICATION_TYPE: applicationEnvironmentType},
		applicationEnvironmentMap[applicationEnvironmentType]
	);
}

export function getEnvironmentConfig(){
	const COMMON_CONFIG = require('./envs/common.json');
	const PRODUCTION_CONFIG = require('./envs/production.json');
	const DEVELOPMENT_CONFIG = require('./envs/development.json');
	const STAGING_CONFIG = require('./envs/staging.json');
	const ENVIRONMENT_TYPE = getEnvironmentType();

	const CONFIG_MAP = {
		[DEVELOPMENT_ENVIRONMENT]: DEVELOPMENT_CONFIG,
		[STAGING_ENVIRONMENT]: STAGING_CONFIG,
		[PRODUCTION_ENVIRONMENT]: PRODUCTION_CONFIG,
	};

	const USED_ENV_CONFIG = CONFIG_MAP[ENVIRONMENT_TYPE];

	return Object.assign({
		environmentType: ENVIRONMENT_TYPE
	}, COMMON_CONFIG, USED_ENV_CONFIG);
}

export function getEnvironmentType(){
	if(process.env.REACT_APP_APPLICATION_ENVIRONMENT) {
		return process.env.REACT_APP_APPLICATION_ENVIRONMENT;
	}

	return process.env.NODE_ENV === 'development'
		? DEVELOPMENT_ENVIRONMENT
		: PRODUCTION_ENVIRONMENT;
}
