import English from "./lang/en.json";
import Turkish from "./lang/en.json";

export const APP_LANGUAGE_STORAGE_KEY = 'APP_LANGUAGE';

export const APP_LANGUAGE_EN = 'en-US';
export const APP_LANGUAGE_TR = 'tr-TR';
export const DEFAULT_LANGUAGE = APP_LANGUAGE_EN;

export const LOCALES = {
	[APP_LANGUAGE_EN]: English,
	[APP_LANGUAGE_TR]: Turkish
};
