import queryString from 'query-string';

export default class URLHelper {

	static getUrlParams = () => {
		if (window.location.search) {
			return queryString.parse(window.location.search);
		}

		return {};
	};

	static getUrlParamValue = (param) => {
		let arrSearch = URLHelper.getUrlParams();
		if (typeof arrSearch[param] !== 'undefined') {
			return arrSearch[param];
		}
		return null;
	};

	static myPapelUrl = (path) => {
		const MY_PAPEL_URL = "https://my.criex.io/";
		return MY_PAPEL_URL + path;
	}

}
