import React, {Suspense} from "react";
import { Route } from "react-router-dom";
import {StaticPageLayoutWrapper, HeaderWrapper} from "./components/Styled";
import StaticPageLayoutHeader from "./components/StaticPageLayoutHeader";
import StaticPageLayoutFooter from "./components/StaticPageLayoutFooter";
import URLHelper from "../../Helpers/URLHelper";

const StaticPageLayout = ({component: Component, hasHeader = true, hasFooter = true,  ...rest}) => {
  const {hideHeader, hideFooter} = URLHelper.getUrlParams();
  const isHeaderDisplayed = hasHeader === true && hideHeader !== 'true';
  const isFooterDisplayed = hasFooter === true && hideFooter !== 'true';

	return (
    <Route {...rest} render={matchProps => (
      <StaticPageLayoutWrapper>
        {isHeaderDisplayed === true && (
        	<HeaderWrapper className={'fixed w-full'}>
	          <StaticPageLayoutHeader />
          </HeaderWrapper>
        )}
	      <Suspense fallback={React.Fragment}>
	        <div className={'flex flex-1 flex-col'}>
		        <div className={'flex flex-1'}>
			        <Component {...matchProps} />
		        </div>
		        {isFooterDisplayed === true && <StaticPageLayoutFooter />}
	        </div>
	      </Suspense>
      </StaticPageLayoutWrapper>
    )} />
  );
};

export default StaticPageLayout;
