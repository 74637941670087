import styled from "styled-components";
import CompanyLogo from "../../../../../../../../../components/Atomic/Branding/CompanyLogo";

export const CompanyInformationSectionContainer = styled.div``;

export const StyledCompanyLogo = styled(CompanyLogo)`
	position: relative;
	left: -15px;
`;

export const CompanyInformationItemText = styled.div`
  display: flex;
	align-content: center;
	justify-content: center;
`;
