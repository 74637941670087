import React from 'react';
import styled from "styled-components";

export const WidthLimitItemsWrapper = styled.div`
	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		${props => props.enablePaddingOnSmallDevices === true && `
				padding: 0 24px;
		`};
	}
`;

const WidthLimitContainer = ({children, enablePaddingOnSmallDevices = true}) => <WidthLimitItemsWrapper className={'container w-full xl:max-w-screen-xl mx-auto'} enablePaddingOnSmallDevices={enablePaddingOnSmallDevices}>{children}</WidthLimitItemsWrapper>;
export default WidthLimitContainer;
