import React from 'react';

import {
	CRIEX_APPLICATION_TYPE,
	PAPELX_APPLICATION_TYPE
} from "../../../../library/Environment/EnvironmentConstants";
import Environment from "../../../../library/Environment";

const CompanyLogo = ({variant, colorVariant, ...props}) => {
	const LOGOS_MAP = {
		[CRIEX_APPLICATION_TYPE]: {
			full: {
				color: require('./assets/criex-logo-color.png').default,
				light: require('./assets/criex-logo-light.png').default,
			},
		},
		[PAPELX_APPLICATION_TYPE]: {
			full: {
				color: require('./assets/papelx-logo-color.png').default,
				light: require('./assets/papelx-logo-light.png').default,
			},
		}
	}

	const variants = LOGOS_MAP[Environment.APPLICATION_TYPE];

  return (
    <img src={variants[variant][colorVariant]} {...props} alt={"Logo"} />
  );
};

CompanyLogo.defaultProps = {
	variant: 'full',
	colorVariant: 'color',
}

export default CompanyLogo;
